import React from "react"

const FeaturedProducts = ({ data }) => {
  return (
    <div className="grid-wrap mb-4 w-full grid grid-cols-2 md:grid-cols-4 grid-rows-2 md:grid-rows-2 gap-4">
      {data.nodes.length === 0
        ? Array.apply(null, Array(5)).map((item, i) => (
            <div
              className={`grid-img-wrap small-grid-card ${
                i == 0 ? "col-span-2 row-span-2" : "col-span-1 row-span-1"
              }`}
            >
              <img
                className="w-full h-full object-cover"
                src="https://media.kubric.io/api/assetlib/5fcad432-cdc0-49ea-b5cf-787a629d6cf0.png"
                alt="product image"
              />
            </div>
          ))
        : data.distinct.map(position => {
            let card = data.nodes.filter(
              elem => elem.meta.position == position
            )[0]
            return (
              <div className={`grid-img-wrap small-grid-card ${
                position === "0" ? "col-span-2 row-span-2" : "col-span-1 row-span-1"
              }`}>
                <a href={card.meta.url ? card.meta.url : "/"}>
                  <img
                    className="w-full h-full object-cover"
                    src={card.url}
                    alt="product image"
                  />
                </a>
              </div>
            )
          })}
    </div>
  )
}

export default FeaturedProducts
